a {
  color: black;
}
a:hover {
  color: rgb(1, 60, 115);
}

#description_row {
  margin-left: 15px;
  margin-right: 10px;
  margin-top: -10px;
  padding-top: 0 !important;
}

#status_label {
  margin-right: 5px;
  display: inline-block;
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
}
