#root {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

*:not(i) {
  font-family: "Sora", sans-serif;
}

.menu-logo:hover {
  background: rgba(0, 0, 0, 0) !important;
}
